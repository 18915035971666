import * as React from 'react'
const SvgKeyBig = ({ title, titleId, ...props }) => (
  <svg
    viewBox="0 0 36 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.81 7.574a3.783 3.783 0 0 0-3.78 3.781 3.783 3.783 0 0 0 3.78 3.781 3.783 3.783 0 0 0 3.782-3.78 3.783 3.783 0 0 0-3.781-3.782Zm-1.793 3.781a1.794 1.794 0 1 1 3.588.001 1.794 1.794 0 0 1-3.588 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.81.606c-5.933 0-10.75 4.816-10.75 10.75 0 .255.01.509.028.76l.013.181L1.23 25.166A3.782 3.782 0 0 0 .126 27.84v4.419a3.782 3.782 0 0 0 3.78 3.782H9.48a.996.996 0 0 0 .995-.994v-4.581h4.58a.993.993 0 0 0 .994-.994v-4.58h4.581a.994.994 0 0 0 .703-.292l2.536-2.536.182.013c.25.018.504.027.76.027 5.933 0 10.75-4.816 10.75-10.749C35.56 5.422 30.742.606 24.81.606Zm-8.762 10.75c0-4.837 3.926-8.763 8.762-8.763s8.762 3.926 8.762 8.762-3.926 8.762-8.762 8.762c-.398 0-.79-.026-1.174-.078a.992.992 0 0 0-.835.282l-2.583 2.583h-5.163a.993.993 0 0 0-.994.994v4.58H9.48a.993.993 0 0 0-.994.994v4.581h-4.58c-.991 0-1.794-.803-1.794-1.793v-4.42c0-.477.19-.932.525-1.268l7.727-7.727 5.48-5.481a.993.993 0 0 0 .282-.834l-.078-1.175Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgKeyBig
